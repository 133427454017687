<template>
  <v-container style="height: 90vh;">
      <v-dialog
        max-width="500px"
        v-model="dialog"
      >
        <v-card>
            <v-card-title>Lager hinzufügen</v-card-title>
            <v-divider class="mt-2 mb-5"></v-divider>
            <v-card-text>
                <v-text-field
                outlined
                v-model="data.Title"
                label="Title"
                ></v-text-field>
                <v-text-field
                outlined
                v-model="data.Ort"
                label="Ort"
                ></v-text-field>
                <v-text-field
                outlined
                v-model="data.Jahr"
                label="Jahr"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" text>
                    Schließen
                </v-btn>
                <v-btn
                @click="createLager()" 
                color="success"
                text
                >
                    Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-list>
          <v-list-item link v-for="item in alteLager" :key="item._id" :to="'lager/' + item._id">
              <v-list-item-title>{{ item.Titel }} {{ item.Jahr }}</v-list-item-title>
          </v-list-item>
      </v-list>
      <v-btn
        style="position: absolute; bottom: 0; right: 50px"
        fab
        bottom
        right
        color="primary"
        @click="dialog = true"
       >
       <v-icon>
       mdi-plus
       </v-icon>
       </v-btn>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data:() => {
        return {
        alteLager: [],
        data: [],
        dialog: false,
        }
    },
    methods: {
        getData() {
            axios
            .get('https://kjg-api.rezept-zettel.de/api/vergangeneLager')
            .then(res => {
                this.alteLager = res.data
            })
            .catch(err => {
                console.log(err)
            })
        },
        createLager() {
            axios
            .post('https://kjg-api.rezept-zettel.de/api/vergangeneLager', 
            {
                Titel: this.data.Titel,
                Jahr: this.data.Jahr,
                Ort: this.data.Ort,
            })
        }
    },
    beforeMount() {
        this.getData()
    }
}
</script>

<style>

</style>